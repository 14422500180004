export default function guest({ next, router }) {
	// let loggedUser = localStorage.getItem('user');
	// const accessToken = document.cookie.replace(
	// 	/(?:(?:^|.*;\s*)access_token=\s*([^;]*).*$)|^.*$/,
	// 	'$1'
	// );

	// if (accessToken) {
	// 	return router.push({ name: 'Reports' });
	// }

	if (localStorage.getItem('access_token')) {
		return router.push({ name: 'Reports' });
	}

	return next();
}

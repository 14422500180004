<template>
    <v-app>
        <v-snackbar
            v-for="(snackbar, index) in notifications"
            v-model="snackbar.show"
            :color="snackbar.type"
            :timeout="1900"
            :top="true"
            :right="true"
            :key="index"
        >
            {{ snackbar.message }}
        </v-snackbar>

        <!-- <div class="headers">
            <emb-header :logo="company.logo"></emb-header>
        </div> -->

        <!-- Menu for mobile -->
        <!-- <v-navigation-drawer v-model="mobileMenu" temporary absolute dark class="sidebar-bg">
            <emb-sidebar></emb-sidebar>
        </v-navigation-drawer> -->

        <router-view></router-view>

        <!-- <emb-footer-V1
            :title="`Sobre ` + company.name"
            description="
                Somos la mejor pollería de Lima norte. Ofrecemos pollos a la leña, 
                parrillas, platos criollos, entre otros; acompañados de bebidas naturales al gusto del paladar. 
                Visítenos en Tercer Sector de Villa Los Reyes Mz. J Lote 8. Ventanilla. Referencia: Paradero Flecha."
            img="/static/images/cards.png"
        ></emb-footer-V1> -->

        <!-- <emb-call :showDialog.sync="showDialog"></emb-call> -->

        <!-- <v-fab-transition>
            <v-btn color="accent" dark fixed bottom right fab @click="showDialog = true">
                <v-icon large>mdi-phone-in-talk</v-icon>
            </v-btn>
        </v-fab-transition> -->
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            company: {},
            showDialog: false,
        };
    },
    computed: {
        ...mapGetters(['notifications']),
        mobileMenu: {
            get() {
                return this.$store.getters.sidebarOpen;
            },
            set(val) {
                this.$store.dispatch('toggleSidebar', val);
            },
        },
    },
    // async created() {
    //     await this.getFooterData();
    // },
    // methods: {
    //     getFooterData() {
    //         this.$http.get(`/footer/data`).then((response) => {
    //             this.company = response.data.data.company;
    //         });
    //     },
    // },
};
</script>

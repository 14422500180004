import Admin from 'Container/Adminpanel.vue';
import Middlewares from '../middlewares/';

const Reports = () => import(/* webpackChunkName: "Reports" */ 'Views/AdminPanel/Reports.vue');

const Permissions = () =>
	import(/* webpackChunkName: "Permissions" */ 'Views/Admin/Permissions/Index.vue');
const Roles = () => import(/* webpackChunkName: "Roles" */ 'Views/Admin/Roles/Index.vue');
const Users = () => import(/* webpackChunkName: "Users" */ 'Views/Admin/Users/Index.vue');
const CleaningPersonnel = () => import(/* webpackChunkName: "CleaningPersonnel" */ 'Views/Admin/CleaningPersonnel/Index.vue');
const Establishments = () =>
	import(/* webpackChunkName: "Establishments" */ 'Views/Admin/Establishments/Index.vue');
const CulqiTokens = () =>
	import(/* webpackChunkName: "CulqiTokens" */ 'Views/Admin/CulqiTokens/Index.vue');
const Configurations = () =>
	import(/* webpackChunkName: "Configurations" */ 'Views/Admin/Configurations/Index.vue');
const Tasks = () => import(/* webpackChunkName: "tasks" */ 'Views/Admin/Tasks/Index.vue');
const Correlatives = () =>
	import(/* webpackChunkName: "Correlatives" */ 'Views/Admin/Correlatives/Index.vue');

const Categories = () =>
	import(/* webpackChunkName: "Categories" */ 'Views/Admin/Categories/Index.vue');
const RoomTypes = () =>
	import(/* webpackChunkName: "RoomTypes" */ 'Views/Admin/RoomTypes/Index.vue');
const Rates = () =>
	import(/* webpackChunkName: "Rates" */ 'Views/Admin/Rates/Index.vue');
const Rooms = () =>
	import(/* webpackChunkName: "Rooms" */ 'Views/Admin/Rooms/Index.vue');
const Salons = () => import(/* webpackChunkName: "Salons" */ 'Views/Admin/Salons/Index.vue');
const Shifts = () => import(/* webpackChunkName: "Shifts" */ 'Views/Admin/Shifts/Index.vue');
// const CommandZones = () =>
// 	import(/* webpackChunkName: "CommandZones" */ 'Views/Admin/CommandZones/Index.vue');
const UnitTypes = () =>
	import(/* webpackChunkName: "UnitTypes" */ 'Views/Admin/UnitTypes/Index.vue');

const Items = () => import(/* webpackChunkName: "Items" */ 'Views/Admin/Items/Index.vue');
const Movements = () =>
	import(/* webpackChunkName: "Movements" */ 'Views/Admin/Movements/Index.vue');
const Inventories = () =>
	import(/* webpackChunkName: "Inventories" */ 'Views/Admin/Inventories/Index.vue');

const Rentals = () =>
	import(/* webpackChunkName: "Rentals" */ 'Views/Admin/Rentals/Index.vue');

const RoomsInUse = () => import(/* webpackChunkName: "RoomsInUse" */ 'Views/Admin/RoomsInUse/Index.vue');

const Rent = () => import(/* webpackChunkName: "Rent" */ 'Views/Admin/Rentals/Create.vue');

const RentAdd = () =>
	import(/* webpackChunkName: "RentAdd" */ 'Views/Admin/Rentals/Add.vue');

const RentalCheckout = () =>
	import(/* webpackChunkName: "RentalCheckout" */ 'Views/Admin/Rentals/Checkout.vue');

// const SaleIndex = () => import(/* webpackChunkName: "SaleIndex" */ 'Views/Admin/Sales/Index.vue');

const People = () => import(/* webpackChunkName: "People" */ 'Views/Admin/People/Index.vue');

const DocumentIndex = () =>
	import(/* webpackChunkName: "DocumentIndex" */ 'Views/Admin/Documents/Index.vue');
const Debts = () =>
	import(/* webpackChunkName: "Debts" */ 'Views/Admin/Documents/Debt.vue');
const DocumentCreate = () =>
	import(/* webpackChunkName: "DocumentCreate" */ 'Views/Admin/Documents/Create.vue');

const Note = () =>
	import(/* webpackChunkName: "Note" */ 'Views/Admin/Documents/Note.vue');

	const Summaries = () =>
	import(/* webpackChunkName: "Summaries" */ 'Views/Admin/Summaries/Index.vue');
const Voided = () => import(/* webpackChunkName: "Voided" */ 'Views/Admin/Voided/Index.vue');

const Cashes = () => import(/* webpackChunkName: "cashes" */ 'Views/Admin/Cashes/Index.vue');
const Openings = () => import(/* webpackChunkName: "openings" */ 'Views/Admin/Openings/Index.vue');

const OpeningHistories = () =>
	import(/* webpackChunkName: "histories" */ 'Views/Admin/OpeningHistories/Index.vue');

const DocumentsReports = () =>
	import(/* webpackChunkName: "DocumentsReports" */ 'Views/Admin/Reports/Documents/Index.vue');

const CommandsReports = () =>
	import(/* webpackChunkName: "CommandsReports" */ 'Views/Admin/Reports/Commands/Index.vue');

const SalesReports = () =>
	import(/* webpackChunkName: "SalesReports" */ 'Views/Admin/Reports/Sales/Index.vue');

const CleaningRoomReport = () =>
	import(/* webpackChunkName: "CleaningRoomReport" */ 'Views/Admin/Reports/Cleaning/Index.vue');

const ReasonTravelReport = () =>
	import(/* webpackChunkName: "ReasonTravelReport" */ 'Views/Admin/Reports/ReasonsTravel/Index.vue');

export default {
	path: '/dashboard',
	component: Admin,
	redirect: '/dashboard/reports',
	children: [
		{
			path: 'reports',
			component: Reports,
			name: 'Reports',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_dashboard'],
			},
		},
		{
			path: 'permissions',
			component: Permissions,
			name: 'Permissions',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['permissions_manage'],
			},
		},
		{
			path: 'roles',
			component: Roles,
			name: 'Roles',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['roles_manage'],
			},
		},
		{
			path: 'users',
			component: Users,
			name: 'Users',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['users_manage'],
			},
		},
		{
			path: 'cleaning-personnel',
			component: CleaningPersonnel,
			name: 'CleaningPersonnel',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['users_manage'],
			},
		},
		{
			path: 'establishments',
			component: Establishments,
			name: 'Establishments',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['establishments_manage'],
			},
		},
		{
			path: 'culqi-tokens',
			component: CulqiTokens,
			name: 'CulqiTokens',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['configurations_manage'],
			},
		},
		{
			path: 'configurations',
			component: Configurations,
			name: 'Configurations',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['configurations_manage'],
			},
		},
		{
			path: 'tasks',
			component: Tasks,
			name: 'Tasks',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['tasks_manage'],
			},
		},
		{
			path: 'correlatives',
			component: Correlatives,
			name: 'Correlatives',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['configurations_manage'],
			},
		},
		{
			path: 'categories',
			component: Categories,
			name: 'Categories',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['categories_manage'],
			},
		},
		{
			path: 'room-types',
			component: RoomTypes,
			name: 'RoomTypes',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['rooms_manage'],
			},
		},
		{
			path: 'rates',
			component: Rates,
			name: 'Rates',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['rooms_manage'],
			},
		},
		{
			path: 'salons',
			component: Salons,
			name: 'Salons',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['salons_manage'],
			},
		},
		{
			path: 'shifts',
			component: Shifts,
			name: 'Shifts',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['establishments_manage'],
			},
		},
		{
			path: 'rooms',
			component: Rooms,
			name: 'Rooms',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['rooms_manage'],
			},
		},
		{
			path: 'rent',
			component: Rent,
			name: 'Rent',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_rentals'],
			},
		},
		{
			path: 'rooms-in-use',
			component: RoomsInUse,
			name: 'RoomsInUse',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_rentals'],
			},
		},
		{
			path: 'rental/add',
			component: RentAdd,
			name: 'RentAdd',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_rentals'],
			},
		},
		{
			path: 'rental/add/:id',
			component: RentAdd,
			name: 'RentAddSelected',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_rentals'],
			},
		},
		{
			path: 'rent/checkout/:id',
			component: RentalCheckout,
			name: 'RentalCheckout',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_rentals'],
			},
		},
		{
			path: 'unit-types',
			component: UnitTypes,
			name: 'UnitTypes',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['unit_types_manage'],
			},
		},
		{
			path: 'items',
			component: Items,
			name: 'Items',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['items_manage'],
			},
		},
		
		{
			path: 'movements',
			component: Movements,
			name: 'Movements',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['to_do_transactions_in_warehouses'],
			},
		},
		{
			path: 'inventories',
			component: Inventories,
			name: 'Inventories',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_inventory'],
			},
		},
		{
			path: 'rentals',
			component: Rentals,
			name: 'Rentals',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_rentals'],
			},
		},
		{
			path: 'people/:type',
			component: People,
			name: 'People',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['customers_manage'],
			},
		},
		{
			path: 'documents',
			component: DocumentIndex,
			name: 'DocumentIndex',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_vouchers'],
			},
		},
		{
			path: 'debts',
			component: Debts,
			name: 'Debts',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_voucher'],
			},
		},
		{
			path: 'document/create',
			component: DocumentCreate,
			name: 'documentCreate',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_voucher'],
			},
		},
		{
			path: 'note/:id',
			component: Note,
			name: 'note',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_voucher'],
			},
		},
		{
			path: 'summaries',
			component: Summaries,
			name: 'Summaries',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_voucher'],
			},
		},
		{
			path: 'voided',
			component: Voided,
			name: 'Voided',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_voucher'],
			},
		},
		{
			path: 'cashes',
			component: Cashes,
			name: 'cashes',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['cash_manage'],
			},
		},
		{
			path: 'openings',
			component: Openings,
			name: 'openings',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['to_do_transactions_in_cash'],
			},
		},
		
		{
			path: 'openings-history',
			component: OpeningHistories,
			name: 'openingHistories',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['to_do_transactions_in_cash'],
			},
		},
		{
			path: 'report/documents',
			component: DocumentsReports,
			name: 'DocumentsReports',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_report_of_sales'],
			},
		},
		{
			path: 'report/rentals',
			component: CommandsReports,
			name: 'CommandsReports',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_report_of_sales'],
			},
		},
		{
			path: 'report/sales',
			component: SalesReports,
			name: 'SalesReports',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_report_of_sales'],
			},
		},
		{
			path: 'report/cleaning',
			component: CleaningRoomReport,
			name: 'CleaningRoomReport',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_report_of_sales'],
			},
		},
		{
			path: 'report/reasons-travel',
			component: ReasonTravelReport,
			name: 'ReasonTravelReport',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_report_of_sales'],
			},
		},
	],
};

export default function auth({next, router}) {
    // const accessToken = document.cookie.replace(
    //     /(?:(?:^|.*;\s*)access_token=\s*([^;]*).*$)|^.*$/,
    //     '$1'
    // );

    // if (!accessToken) {
    //     return router.push({name: 'login'});
    // }

    if (!localStorage.getItem('access_token')) {
		return router.push({name: 'login'});
	}

    return next();
}

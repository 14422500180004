import UserPanel from 'Container/UserPanel';
import Middlewares from '../middlewares/';

// const UserPanel = () => import(/* webpackChunkName: "UserPanel" */ 'Container/UserPanel');

// const Home = () => import(/* webpackChunkName: "Home" */ 'Views/Home');
// const Menu = () => import(/* webpackChunkName: "Menu" */ 'Views/User/Cart/Menu');
// const Promotions = () => import(/* webpackChunkName: "Promotions" */ 'Views/User/Cart/Promotion');
// const Reservations = () => import(/* webpackChunkName: "Reservations" */ 'Views/User/Reservations');
// const Locals = () => import(/* webpackChunkName: "Locals" */ 'Views/User/Locals');
// const Payment = () => import(/* webpackChunkName: "Payment" */ 'Views/User/Payment/Payment');
// const Cart = () => import(/* webpackChunkName: "Cart" */ 'Views/User/Cart/Cart');
// const Checkout = () => import(/* webpackChunkName: "Checkout" */ 'Views/User/Cart/Checkout');
// const FinalReceipt = () => import(/* webpackChunkName: "FinalReceipt" */ 'Views/User/Cart/FinalReceipt');
// const AboutUs = () => import(/* webpackChunkName: "AboutUs" */ 'Views/AboutUs');
// const TermCondiition = () => import(/* webpackChunkName: "TermsAndCondition" */ 'Views/User/TermsAndCondition');
// const Faq = () => import(/* webpackChunkName: "Faq" */ 'Views/User/Faq');
// const ContactUs = () => import(/* webpackChunkName: "Contact" */ 'Views/User/Contact');
// const PrivacyPolicy = () => import(/* webpackChunkName: "PrivacyPolicy" */ 'Views/User/PrivacyPolicy');
const Login = () => import(/* webpackChunkName: "Login" */ 'Views/Auth/Login.vue');
// const Register = () => import(/* webpackChunkName: "Register" */ 'Views/Auth/Register');
// const Verify = () => import(/* webpackChunkName: "Verify" */ 'Views/Auth/Verify');

export default {
	path: '/',
	component: UserPanel,
	redirect:'/login',
	children:[
		{
			path: '/login',
			component: Login,
			name: 'login',
			meta: {
				middleware: [Middlewares.guest],
            }
		},
		// { 
		// 	path: '/register',  
		// 	component: Register, 
		// 	name:'register',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
		// 	}
		// },
		// {
		// 	path: '/verify',  
		// 	component: Verify, 
		// 	name:'verify',
		// 	meta: {
		// 		requiresAuth: true,
		// 		onlyAdmin: true
		// 	}
		// },
		// { 
		// 	path: '/',
		// 	name: 'home',
		// 	component: Home,
		// 	meta: {
		// 		middleware: [Middlewares.guest],
		// 	}
		// },
		// {			
		// 	path: '/menu',
		// 	component: Menu,
		// 	name: 'menu',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
        //     }
		// },
		// {			
		// 	path: '/promociones',
		// 	component: Promotions,
		// 	name: 'promotions',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
        //     }
		// },
		// {			
		// 	path: '/reservaciones',
		// 	component: Reservations,
		// 	name: 'reservations',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
        //     }
		// },
		// {			
		// 	path: '/locales',
		// 	component: Locals,
		// 	name: 'locals',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
        //     }
		// },
		// { 
		// 	path: '/cart',
		// 	component: Cart,
		// 	name: 'cart',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
        //     }
		// },
		// { 
		// 	path: '/payment',
		// 	component: Payment,
		// 	name: 'payment',
		// 	meta: {
		// 		requiresAuth: true,
		// 		onlyAdmin: false
        //     }
		// },
		// { 
		// 	path: '/final-receipt/:id',  
		// 	component: FinalReceipt, 
		// 	name:'FinalReceipt',
		// 	meta: {
		// 		requiresAuth: true,
		// 		onlyAdmin: false
        //     }
		// },
		// { 
		// 	path: '/checkout',
		// 	component: Checkout,
		// 	name: 'checkout',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
        //     }
		// },

		// { 
		// 	path: '/about',  
		// 	component: AboutUs, 
		// 	name:'about',
		// 	meta: {
        //         requiresAuth: false,
        //     }
		// },
		// { 
		// 	path: '/term-condition',  
		// 	component: TermCondiition, 
		// 	name:'term-condition',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
        //     }
		// },
		// { 
		// 	path: '/faq',  
		// 	component: Faq, 
		// 	name:'Faq',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
        //     }
		// },
		// { 
		// 	path: '/contact',  
		// 	component: ContactUs, 
		// 	name:'ContactUs',
		// 	meta: {
        //         requiresAuth: false,
        //     }
		// },
		// { 
		// 	path: '/privacy-policy',  
		// 	component: PrivacyPolicy, 
		// 	name:'PrivacyPolicy',
		// 	meta: {
		// 		middleware: [Middlewares.guest],
        //     }
		// }
	]
}